<template>
  <div id="analysis-report-shell">
    <div class="export-btn">
      <a-button type="primary" @click="onOutTable">导出</a-button>
    </div>
    <analysis-template @onDateChange="onDateChange"></analysis-template>
  </div>
</template>

<script>
import dayjs from "dayjs";
import AnalysisTemplate from "../../components/AnalysisTemplate.vue";

export default {
  name: "Home",
  components: {},
  data() {
    return {
       TOTALDAY: dayjs(new Date()).format("YYYY-MM-DD"), //统计时间
    };
  },

  components: {
    AnalysisTemplate,
  },
  methods: {
    //onDateChange 时间变化
    onDateChange(date){
      console.log(date);
      this.TOTALDAY=date;
    },
    //导出
    onOutTable() {
      //   this.$router.push("/export");
      //   let routeData = this.$router.push("/export");
      //   window.open(routeData.href, "_blank");

      let routeData = this.$router.resolve({ path: "/export", query:{date:this.TOTALDAY} });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
#analysis-report-shell {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0 0;
  color: #181818;
  position: relative;

  .export-btn {
    position: absolute;
    top: 60px;
    right: 40px;

    button {
      background-color: #1876ff;
    }
  }
}
</style>
